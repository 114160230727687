import React from "react";

/**
 * @param children Children react elements to render inside the centered container
 * @param responsive Whether the container should responsively adjust based on screen dimension, defaults to true.
 * 
 * Sometimes setting responsive to true may cause distorted height of elements, in which case it should be set to false as long as the component
 * fits inside the screen normally.
 */
const CenteredContainer = ({ children, responsive = true }) => {
    return <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
                <div className={responsive ? 'w-full lg:w-4/12 px-4' : ''}>
                    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                        <div className="rounded-t mb-0 px-6 py-6">
                        {children}
                        </div>
                    </div>
                </div>
            </div>
            </div>

}


export default CenteredContainer